import Vue from "vue";
import Router from "vue-router";
import i18n from "./i18n";

Vue.use(Router);

import BlogEntries from "./blog/blog.json";
const blogRoutes = Object.keys(BlogEntries).map((section) => {
  const children = BlogEntries[section].map((child) => ({
    path: child.id,
    name: child.id,
    meta: {
      title: child.title,
      description: child.description,
      date: child.date,
      wordCount: child.wordCount,
      keywords: child.keywords,
    },
    component: () => import(`./blog/${section}/${child.id}.md`),
  }));
  return {
    path: `/gr/blog`,
    name: section,
    component: () => import("./views/Post.vue"),
    children,
  };
});

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: i18n.locale,
    },
    {
      path: "/:lang(en|gr)",
      component: {
        render: (h) => h("router-view"),
      },
      children: [
        {
          path: "",
          name: "home",
          component: () =>
            import(/* webpackChunkName: "home" */ "./views/Home.vue"),
        },
        {
          path: "about",
          name: "about",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/About.vue"),
          pathToRegexpOptions: { strict: true },
        },
        {
          path: "online_nutrition",
          name: "online_nutrition",
          component: () =>
            import(
              /* webpackChunkName: "services" */ "./views/OnlineNutrition.vue"
            ),
          pathToRegexpOptions: { strict: true },
        },
        {
          path: "services",
          name: "services",
          component: () =>
            import(/* webpackChunkName: "services" */ "./views/Services.vue"),
          pathToRegexpOptions: { strict: true },
        },
        {
          path: "packages",
          name: "packages",
          component: () =>
            import(/* webpackChunkName: "packages" */ "./views/Packages.vue"),
          pathToRegexpOptions: { strict: true },
        },
        {
          path: "gesy",
          name: "gesy",
          component: () =>
            import(/* webpackChunkName: "gesy" */ "./views/Gesy.vue"),
          pathToRegexpOptions: { strict: true },
        },
        {
          path: "appointments",
          name: "appointments",
          component: () =>
            import(
              /* webpackChunkName: "appointments" */ "./views/Appointments.vue"
            ),
          pathToRegexpOptions: { strict: true },
        },
        {
          path: "blog",
          name: "blog",
          component: () =>
            import(/* webpackChunkName: "blog" */ "./views/Blog.vue"),
          pathToRegexpOptions: { strict: true },
        },
        ...blogRoutes,
        // {
        //   path: "*",
        //   redirect: `/`,
        // },
        {
          path: "*",
          component: () => import("./views/404.vue"),
        },
      ],
    },
    {
      path: "*",
      component: () => import("./views/404.vue"),
    },
  ],
});
