<template>
  <Layout>
    <!-- Header -->
    <section class="text-gray-700 body-font">
      <div
        class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center"
      >
        <div
          class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
        >
          <h2 class="title-font sm:text-4xl text-3xl font-medium text-gray-900">
            {{ $t("pages.index.name") }}
          </h2>
          <div
            class="title-font sm:text-2xl text-xl mb-4 font-medium text-gray-800"
          >
            BSc MSc PhD RD
          </div>
          <h1 class="leading-relaxed">
            {{ $t("pages.index.description_pt1") }}
          </h1>
          <p class="mb-2 leading-relaxed">
            {{ $t("pages.index.description_pt2") }}
          </p>
          <div class="justify-center">
            <div class="mt-4">
              <router-link :to="`/${$i18n.locale}/appointments`">
                <input
                  type="submit"
                  name="book"
                  :value="$t('pages.index.appointment')"
                  class="text-sm text-white tracking-wide font-semibold py-3 px-10 rounded-lg inline-flex shadow-lg items-center bg-green-500 hover:bg-green-600"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            class="object-cover object-center"
            alt="MedInDiet"
            src="@/assets/illustrations/undraw_medical_research.svg"
          />
        </div>
      </div>
    </section>
    <!-- END Header -->

    <!-- Services -->
    <div class="bg-gray-100 py-16">
      <div class="container mx-auto relative">
        <div class="font-bold text-4xl pb-4 text-center">
          {{ $t("pages.index.services") }}
        </div>
        <div class="flex flex-col text-sm">
          <div class="flex flex-wrap text-center justify-center">
            <router-link
              :to="`/${$i18n.locale}/services#cardiovascular_disease`"
            >
              <div class="enlarge px-6 py-4">
                <div class="flex items-center justify-center mb-2">
                  <img
                    src="@/assets/services/cardiovascular_disease.svg"
                    alt="cardiovascular disease"
                  />
                </div>
                <h2 class="text-copy-primary">
                  {{ $t("pages.index.cardiovascular") }}
                  <br />
                  {{ $t("pages.index.disease") }}
                </h2>
              </div>
            </router-link>
            <router-link
              :to="`/${$i18n.locale}/services#gastrointestinal_disorders`"
            >
              <div class="enlarge px-6 py-4">
                <div class="flex items-center justify-center mb-2">
                  <img
                    src="@/assets/services/gastrointestinal_disorders.svg"
                    alt="gastrointestinal disorders"
                  />
                </div>
                <h2 class="text-copy-primary">
                  {{ $t("pages.index.gastrointestinal") }}
                  <br />
                  {{ $t("pages.index.disorders") }}
                </h2>
              </div>
            </router-link>
            <router-link :to="`/${$i18n.locale}/services#mental_health`">
              <div class="enlarge px-6 py-4">
                <div class="flex items-center justify-center mb-2">
                  <img
                    src="@/assets/services/mental_health.svg"
                    alt="mental health"
                  />
                </div>
                <h2 class="text-copy-primary">
                  {{ $t("pages.index.mental") }}
                  <br />
                  {{ $t("pages.index.health_") }}
                </h2>
              </div>
            </router-link>
            <router-link
              :to="`/${$i18n.locale}/services#type_ii_diabetes_melitus`"
            >
              <div class="enlarge px-6 py-4">
                <div class="flex items-center justify-center mb-2">
                  <img
                    src="@/assets/services/type_ii_diabetes_melitus.svg"
                    alt="diabetes"
                  />
                </div>
                <h2 class="text-copy-primary">
                  {{ $t("pages.index.diabetes") }}
                </h2>
              </div>
            </router-link>
            <router-link :to="`/${$i18n.locale}/services#nutrition_support`">
              <div class="enlarge px-6 py-4">
                <div class="flex items-center justify-center mb-2">
                  <img
                    src="@/assets/services/nutrition_support.svg"
                    alt="nutrition support"
                  />
                </div>
                <h2 class="text-copy-primary">
                  {{ $t("pages.index.nutrition") }}
                  <br />
                  {{ $t("pages.index.support") }}
                </h2>
              </div>
            </router-link>
            <router-link :to="`/${$i18n.locale}/services#sports_and_exercise`">
              <div class="enlarge px-6 py-4">
                <div class="flex items-center justify-center mb-2">
                  <img
                    src="@/assets/services/sports_and_exercise.svg"
                    alt="sports and exercise"
                  />
                </div>
                <h2 class="text-copy-primary">
                  {{ $t("pages.index.sports") }}
                  <br />
                  {{ $t("pages.index.exercise") }}
                </h2>
              </div>
            </router-link>
            <router-link :to="`/${$i18n.locale}/services#weight_management`">
              <div class="enlarge px-6 py-4">
                <div class="flex items-center justify-center mb-2">
                  <img
                    src="@/assets/services/weight_management.svg"
                    alt="weight management"
                  />
                </div>
                <h2 class="text-copy-primary">
                  {{ $t("pages.index.weight") }}
                  <br />
                  {{ $t("pages.index.management") }}
                </h2>
              </div>
            </router-link>
            <router-link :to="`/${$i18n.locale}/services#womens_health`">
              <div class="enlarge px-6 py-4">
                <div class="flex items-center justify-center mb-2">
                  <img
                    src="@/assets/services/womens_health.svg"
                    alt="women's health"
                  />
                </div>
                <h2 class="text-copy-primary">
                  {{ $t("pages.index.women") }}
                  <br />
                  {{ $t("pages.index.health") }}
                </h2>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- END Services -->

    <!-- Packages -->
    <div class="container-inner mx-auto relative py-16">
      <div class="font-bold text-4xl pb-6 text-center">
        {{ $t("pages.index.packages") }}
      </div>
      <div class="text-base text-center pb-6">
        {{ $t("pages.index.packages_description") }}
      </div>
      <div class="container mx-auto">
        <div class="flex flex-wrap -mx-1 lg:-mx-4 justify-center">
          <!-- Column -->
          <div class="my-1 px-1 pt-8 w-64 md:w-1/3 lg:px-4">
            <article
              class="rounded overflow-hidden shadow-lg border-t-8 border-green-400 bg-white h-56"
            >
              <header class="px-3 py-4">
                <div class="font-semibold text-lg text-center">
                  {{ $t("pages.index.one_to_one") }}
                </div>
              </header>
              <div class="px-2">
                <ul class="flex flex-col items-center text-base text-center">
                  <router-link
                    :to="`/${$i18n.locale}/packages#one_off_package`"
                  >
                    <li class="mb-2 text-copy-primary hover:text-green-600">
                      {{ $t("pages.index.one_off_pack") }}
                    </li>
                  </router-link>
                  <router-link :to="`/${$i18n.locale}/packages#full_package`">
                    <li class="text-copy-primary hover:text-green-600">
                      {{ $t("pages.index.full_package") }}
                    </li>
                  </router-link>
                </ul>
              </div>
            </article>
          </div>
          <!-- END Column -->
          <!-- Column -->
          <div class="my-1 px-1 pt-8 lg:pt-0 w-64 md:w-1/3 lg:px-4">
            <article
              class="rounded overflow-hidden shadow-lg border-t-8 border-green-600 bg-white h-64 lg:h-64"
            >
              <header class="px-6 py-4">
                <div class="font-semibold text-lg text-center">
                  {{ $t("pages.index.group_packages") }}
                </div>
              </header>
              <div class="px-2">
                <ul class="flex flex-col items-center text-base text-center">
                  <router-link
                    :to="`/${$i18n.locale}/packages#couples_and_families`"
                  >
                    <li class="mb-2 text-copy-primary hover:text-green-600">
                      {{ $t("pages.index.couples") }}
                    </li>
                  </router-link>
                  <router-link
                    :to="`/${$i18n.locale}/packages#couples_and_families`"
                  >
                    <li class="mb-2 text-copy-primary hover:text-green-600">
                      {{ $t("pages.index.families") }}
                    </li>
                  </router-link>
                  <router-link :to="`/${$i18n.locale}/packages#education`">
                    <li class="mb-2 text-copy-primary hover:text-green-600">
                      {{ $t("pages.index.education") }}
                    </li>
                  </router-link>
                  <router-link :to="`/${$i18n.locale}/packages#corporate`">
                    <li class="mb-2 text-copy-primary hover:text-green-600">
                      {{ $t("pages.index.corporate") }}
                    </li>
                  </router-link>
                  <router-link :to="`/${$i18n.locale}/packages#sports_teams`">
                    <li class="text-copy-primary hover:text-green-600">
                      {{ $t("pages.index.sports_teams") }}
                    </li>
                  </router-link>
                </ul>
              </div>
            </article>
          </div>
          <!-- END Column -->
          <!-- Column -->
          <div class="my-1 px-1 pt-8 w-64 md:w-1/3 lg:px-4">
            <article
              class="rounded overflow-hidden shadow-lg border-t-8 border-green-400 bg-white h-56"
            >
              <header class="px-3 py-4">
                <div class="font-semibold text-lg text-center">
                  {{ $t("pages.index.gesy_patients") }}
                </div>
              </header>
              <div class="px-2">
                <ul class="flex flex-col items-center text-base text-center">
                  <router-link :to="`/${$i18n.locale}/gesy`">
                    <li class="mb-1 text-copy-primary hover:text-green-600">
                      {{ $t("pages.index.gesy") }}
                    </li>
                  </router-link>
                </ul>
              </div>
            </article>
          </div>
          <!-- END Column -->
        </div>
      </div>
    </div>
    <!-- END Packages -->

    <!-- Appointments -->
    <div class="py-16 bg-gray-100 relative">
      <div class="container-inner mx-auto pb-56 lg:flex lg:flex-row">
        <div class>
          <h2 class="font-bold text-4xl text-center pb-4">
            {{ $t("pages.index.book_appointment") }}
          </h2>
          <div class="text-base text-center sm:text-center pb-4">
            {{ $t("pages.index.book_appointment_text") }}
          </div>
          <div class="text-center">
            <router-link :to="`/${$i18n.locale}/appointments`">
              <input
                type="submit"
                name="book"
                :value="$t('pages.index.appointment')"
                class="text-sm text-white tracking-wide font-semibold py-3 px-10 rounded-lg inline-flex shadow-lg items-center bg-green-500 hover:bg-green-600"
              />
            </router-link>
          </div>
        </div>
      </div>
      <div class="absolute right-0 bottom-0 z-0">
        <img
          src="@/assets/illustrations/undraw_booking.svg"
          alt="book appointment"
          class="h-64 xl:h-80"
        />
      </div>
    </div>
    <!-- END Appointments -->

    <!-- Contact -->
    <div class="pt-16 pb-6" id="contact">
      <div class="font-bold text-4xl text-center justify-centers">
        {{ $t("pages.index.contact") }}
      </div>
    </div>
    <div
      class="container mx-auto lg:flex lg:flex-row items-center pb-16 text-white"
    >
      <GoogleMaps />
      <div
        class="bg-contact flex flex-col justify-center items-start pl-3 pt-6"
      >
        <div class="text-xl font-semibold pt-1">
          {{ $t("pages.index.working_hours") }}
        </div>
        <div class="mt-1">
          {{ $t("pages.index.working_hours_description_pt1") }}
        </div>
        <div class="text-xl mt-6 mb-1 font-semibold">
          {{ $t("pages.index.contact_information") }}
        </div>

        <ul class="flex mb-6 justify-between items-center mr-2">
          <li class="mr-2">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.5" cy="16.5" r="16.5" fill="#38a169" />
              <path
                d="M10.62 15.79C12.06 18.62 14.38 20.94 17.21 22.38L19.41 20.18C19.69 19.9 20.08 19.82 20.43 19.93C21.55 20.3 22.75 20.5 24 20.5C24.2652 20.5 24.5196 20.6054 24.7071 20.7929C24.8946 20.9804 25 21.2348 25 21.5V25C25 25.2652 24.8946 25.5196 24.7071 25.7071C24.5196 25.8946 24.2652 26 24 26C19.4913 26 15.1673 24.2089 11.9792 21.0208C8.79107 17.8327 7 13.5087 7 9C7 8.73478 7.10536 8.48043 7.29289 8.29289C7.48043 8.10536 7.73478 8 8 8H11.5C11.7652 8 12.0196 8.10536 12.2071 8.29289C12.3946 8.48043 12.5 8.73478 12.5 9C12.5 10.25 12.7 11.45 13.07 12.57C13.18 12.92 13.1 13.31 12.82 13.59L10.62 15.79Z"
                fill="white"
              />
            </svg>
          </li>
          <li>
            <a href="tel:+357 99009222" class="text-white hover:text-green-600"
              >+357 99009222</a
            >
          </li>
        </ul>

        <ul class="flex mb-6 justify-between items-center mr-2">
          <li class="mr-2">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.5" cy="16.5" r="16.5" fill="#38a169" />
              <path
                d="M24.1667 12.6667L16.5 17.4584L8.83334 12.6667V10.75L16.5 15.5417L24.1667 10.75V12.6667ZM24.1667 8.83337H8.83334C7.76959 8.83337 6.91667 9.68629 6.91667 10.75V22.25C6.91667 22.7584 7.11861 23.2459 7.47805 23.6053C7.8375 23.9648 8.32501 24.1667 8.83334 24.1667H24.1667C24.675 24.1667 25.1625 23.9648 25.522 23.6053C25.8814 23.2459 26.0833 22.7584 26.0833 22.25V10.75C26.0833 10.2417 25.8814 9.7542 25.522 9.39475C25.1625 9.03531 24.675 8.83337 24.1667 8.83337Z"
                fill="white"
              />
            </svg>
          </li>
          <li>drargyridou@medindiet.com</li>
        </ul>

        <ul class="flex items-center">
          <li class="mr-2">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.5" cy="16.5" r="16.5" fill="#38a169" />
              <path
                d="M17 16.5C16.337 16.5 15.7011 16.2366 15.2322 15.7678C14.7634 15.2989 14.5 14.663 14.5 14C14.5 13.337 14.7634 12.7011 15.2322 12.2322C15.7011 11.7634 16.337 11.5 17 11.5C17.663 11.5 18.2989 11.7634 18.7678 12.2322C19.2366 12.7011 19.5 13.337 19.5 14C19.5 14.3283 19.4353 14.6534 19.3097 14.9567C19.1841 15.26 18.9999 15.5356 18.7678 15.7678C18.5356 15.9999 18.26 16.1841 17.9567 16.3097C17.6534 16.4353 17.3283 16.5 17 16.5ZM17 7C15.1435 7 13.363 7.7375 12.0503 9.05025C10.7375 10.363 10 12.1435 10 14C10 19.25 17 27 17 27C17 27 24 19.25 24 14C24 12.1435 23.2625 10.363 21.9497 9.05025C20.637 7.7375 18.8565 7 17 7Z"
                fill="white"
              />
            </svg>
          </li>
          <li>{{ $t("pages.index.address") }}</li>
        </ul>
        <div class="text-xl mt-6 mb-1 font-semibold">
          {{ $t("pages.index.social_media") }}
        </div>
        <div class="flex flex-col justify-between items-center pb-16">
          <ul class="flex items-center">
            <li class="mr-1">
              <a
                href="https://www.facebook.com/medinidiet"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="facebook"
                class="text-green-600 hover:text-green-700"
              >
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 45 45"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.1194 39.3693V24.0018H30.3037L31.0744 17.985H25.1194V14.1525C25.1194 12.4162 25.6031 11.2275 28.095 11.2275H31.2525V5.86308C29.7162 5.69843 28.172 5.61894 26.6269 5.62495C22.0444 5.62495 18.8981 8.42245 18.8981 13.5581V17.9737H13.7475V23.9906H18.9094V39.3693H25.1194Z"
                    fill-rule="nonzero"
                  />
                </svg>
              </a>
            </li>
            <li class="mr-4">
              <a
                href="https://www.instagram.com/medindiet/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="instagram"
                class="text-green-600 hover:text-green-700"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 45 45"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.625 3.75H30.375C36.375 3.75 41.25 8.625 41.25 14.625V30.375C41.25 33.2592 40.1042 36.0253 38.0648 38.0648C36.0253 40.1043 33.2592 41.25 30.375 41.25H14.625C8.625 41.25 3.75 36.375 3.75 30.375V14.625C3.75 11.7408 4.89576 8.97467 6.93521 6.93521C8.97467 4.89576 11.7408 3.75 14.625 3.75ZM14.25 7.5C12.4598 7.5 10.7429 8.21116 9.47703 9.47703C8.21116 10.7429 7.5 12.4598 7.5 14.25V30.75C7.5 34.4813 10.5187 37.5 14.25 37.5H30.75C32.5402 37.5 34.2571 36.7888 35.523 35.523C36.7888 34.2571 37.5 32.5402 37.5 30.75V14.25C37.5 10.5188 34.4812 7.5 30.75 7.5H14.25ZM32.3438 10.3125C32.9654 10.3125 33.5615 10.5594 34.001 10.999C34.4406 11.4385 34.6875 12.0346 34.6875 12.6563C34.6875 13.2779 34.4406 13.874 34.001 14.3135C33.5615 14.7531 32.9654 15 32.3438 15C31.7221 15 31.126 14.7531 30.6865 14.3135C30.2469 13.874 30 13.2779 30 12.6563C30 12.0346 30.2469 11.4385 30.6865 10.999C31.126 10.5594 31.7221 10.3125 32.3438 10.3125ZM22.5 13.125C24.9864 13.125 27.371 14.1127 29.1291 15.8709C30.8873 17.629 31.875 20.0136 31.875 22.5C31.875 24.9864 30.8873 27.371 29.1291 29.1291C27.371 30.8873 24.9864 31.875 22.5 31.875C20.0136 31.875 17.629 30.8873 15.8709 29.1291C14.1127 27.371 13.125 24.9864 13.125 22.5C13.125 20.0136 14.1127 17.629 15.8709 15.8709C17.629 14.1127 20.0136 13.125 22.5 13.125ZM22.5 16.875C21.0082 16.875 19.5774 17.4676 18.5225 18.5225C17.4676 19.5774 16.875 21.0082 16.875 22.5C16.875 23.9918 17.4676 25.4226 18.5225 26.4775C19.5774 27.5324 21.0082 28.125 22.5 28.125C23.9918 28.125 25.4226 27.5324 26.4775 26.4775C27.5324 25.4226 28.125 23.9918 28.125 22.5C28.125 21.0082 27.5324 19.5774 26.4775 18.5225C25.4226 17.4676 23.9918 16.875 22.5 16.875Z"
                    fill-rule="nonzero"
                  />
                </svg>
              </a>
            </li>
            <li class="mr-4">
              <a
                href="https://twitter.com/SArgyridou"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="twitter"
                class="text-green-600 hover:text-green-700"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 20 17"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.807 1.943a8.588 8.588 0 0 1-2.036 2.135 11.918 11.918 0 0 1-.465 3.854 12.085 12.085 0 0 1-1.452 3.177 12.426 12.426 0 0 1-2.318 2.691c-.897.78-1.978 1.402-3.243 1.867-1.265.464-2.618.697-4.06.697-2.27 0-4.348-.618-6.233-1.854.293.034.62.051.98.051 1.885 0 3.565-.588 5.04-1.764a3.915 3.915 0 0 1-2.363-.825 4.038 4.038 0 0 1-1.432-2.039c.276.043.532.064.766.064.36 0 .717-.047 1.068-.14A3.982 3.982 0 0 1 1.73 8.43c-.617-.754-.925-1.63-.925-2.627v-.051c.57.324 1.182.498 1.835.524a4.1 4.1 0 0 1-1.32-1.47 4.078 4.078 0 0 1-.49-1.969c0-.75.185-1.445.553-2.084a11.548 11.548 0 0 0 3.702 3.05 11.163 11.163 0 0 0 4.669 1.271c-.067-.324-.1-.639-.1-.946 0-1.142.395-2.116 1.187-2.92C11.632.402 12.589 0 13.712 0c1.173 0 2.161.435 2.966 1.304a7.878 7.878 0 0 0 2.576-.997 3.997 3.997 0 0 1-1.785 2.275 7.98 7.98 0 0 0 2.338-.639z"
                    fill-rule="nonzero"
                  />
                </svg>
              </a>
            </li>
            <li class="mr-4 mb-1">
              <a
                href="https://www.linkedin.com/in/stavroula-argyridou"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="linkedin"
                class="text-green-600 hover:text-green-700"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5,3c0,1.1-0.7,2-2,2C1.8,5,1,4.1,1,3.1C1,2,1.8,1,3,1S5,1.9,5,3z M1,19h4V6H1V19z M14.6,6.2c-2.1,0-3.3,1.2-3.8,2h-0.1 l-0.2-1.7H6.9C6.9,7.6,7,8.9,7,10.4V19h4v-7.1c0-0.4,0-0.7,0.1-1c0.3-0.7,0.8-1.6,1.9-1.6c1.4,0,2,1.2,2,2.8V19h4v-7.4 C19,7.9,17.1,6.2,14.6,6.2z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- END Contact -->
    <!-- eBook -->
    <div class="px-5 py-16 bg-gray-100 relative">
      <div class="font-bold text-4xl text-center">
        {{ $t("pages.index.ebook") }}
      </div>
      <div
        class="container-inner mx-auto flex items-center justify-center flex-col text-center"
      >
        <img
          alt="content"
          class="object-cover object-center h-80 rounded-lg"
          src="@/assets/ebook_cover.png"
        />

        <h2 class="title-font text-xl font-medium text-gray-900 mb-3">
          {{ $t("pages.index.free_ebook") }}
        </h2>
        <p class="leading-relaxed text-base pb-4">
          {{ $t("pages.index.ebook_text") }}
        </p>
        <button
          v-if="!isDownloading"
          class="text-sm text-white tracking-wide font-semibold py-3 px-10 rounded-lg inline-flex shadow-lg items-center bg-green-500 hover:bg-green-600"
          @click="downloadWithAxios()"
        >
          {{ $t("pages.index.download") }}
        </button>
        <button
          v-if="isDownloading"
          class="text-sm text-white tracking-wide font-semibold py-3 pr-10 rounded-lg inline-flex shadow-lg items-center bg-green-500"
        >
          <img
            src="@/assets/spinner.svg"
            alt="spinner"
            class="h-5 w-5 ml-3 mr-2"
          />
          {{ $t("pages.index.download") }}
        </button>
      </div>
    </div>
    <!-- END eBook -->
    <!-- Testimonials -->
    <div class="px-5 py-16 relative">
      <div class="font-bold text-4xl pb-6 text-center">
        {{ $t("pages.index.testimonials") }}
      </div>
      <div class="container mx-auto pb-56">
        <div class="flex flex-wrap -m-4">
          <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
            <div class="h-full text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="inline-block w-8 h-8 text-gray-400 mb-8"
                viewBox="0 0 975.036 975.036"
              >
                <path
                  d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
                ></path>
              </svg>
              <p class="leading-relaxed">
                {{ $t("pages.index.testimonial1") }}
              </p>
              <span
                class="inline-block h-1 w-10 rounded bg-green-500 mt-6 mb-4"
              ></span>
              <div class="text-gray-900 font-medium title-font tracking-wider">
                {{ $t("pages.index.testimonial_name1") }}
              </div>
            </div>
          </div>
          <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
            <div class="h-full text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="inline-block w-8 h-8 text-gray-400 mb-8"
                viewBox="0 0 975.036 975.036"
              >
                <path
                  d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
                ></path>
              </svg>
              <p class="leading-relaxed">
                {{ $t("pages.index.testimonial2") }}
              </p>
              <span
                class="inline-block h-1 w-10 rounded bg-green-500 mt-6 mb-4"
              ></span>
              <div class="text-gray-900 font-medium title-font tracking-wider">
                {{ $t("pages.index.testimonial_name2") }}
              </div>
            </div>
          </div>
          <div class="lg:w-1/3 lg:mb-0 p-4">
            <div class="h-full text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="inline-block w-8 h-8 text-gray-400 mb-8"
                viewBox="0 0 975.036 975.036"
              >
                <path
                  d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
                ></path>
              </svg>
              <p class="leading-relaxed">
                {{ $t("pages.index.testimonial3") }}
              </p>
              <span
                class="inline-block h-1 w-10 rounded bg-green-500 mt-6 mb-4"
              ></span>
              <div class="text-gray-900 font-medium title-font tracking-wider">
                {{ $t("pages.index.testimonial_name3") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute right-0 bottom-0 z-0">
        <img
          src="@/assets/illustrations/undraw_review.svg"
          alt="book appointment"
          class="h-64 xl:h-80"
        />
      </div>
    </div>
    <!-- END Testimonials -->
  </Layout>
</template>

<script>
import GoogleMaps from "../components/GoogleMaps";
import axios from "axios";

export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title:
        locale == "gr"
          ? "Κλινική Διαιτολόγος - Διατροφολόγος Λευκωσία | Σταυρούλα Αργυρίδου "
          : "Clinical Dietitian- Nutritionist Nicosia | Stavroula Argyridou",
      meta:
        locale == "gr"
          ? [
              {
                name: "description",
                content:
                  "Δρ. Αργυρίδου Σταυρούλα, Εγγεγραμμένη Κλινική Διαιτολόγος - Διατροφολόγος, Λευκωσία, Κύπρος. Εξειδίκευση στην πρόληψη του Διαβήτη Τύπου ΙΙ & των Καρδιαγγειακών Νοσημάτων",
              },
              {
                name: "keywords",
                content:
                  "Διαιτολόγος, Διαιτολόγος Κύπρος, Διαιτολόγος Λευκωσία, Διατροφολόγος Κύπρος, Διατροφολόγος Λευκωσία",
              },
            ]
          : [
              {
                name: "description",
                content:
                  "MedInDiet: Dr. Argyridou Stavroula, Registered Clinical Dietitian - Nutritionist, Nicosia, Cyprus .Specialist in Type II Diabetes & Cardiovascular Disease Prevention",
              },
              {
                name: "keywords",
                content: "Dietitian, Dietitian Cyprus, Dietitian Nicosia",
              },
            ],
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en",
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org/",
            "@type": "Person",
            name: "Stavroula Argyridou",
            url: "https://medindiet.com/",
            image: "https://medindiet.com/img/stav_photo_small.jpg",
            sameAs: [
              "https://www.facebook.com/medinidiet",
              "https://twitter.com/SArgyridou",
              "https://www.linkedin.com/in/stavroula-argyridou",
              "https://www.instagram.com/medindiet/",
            ],
            jobTitle: "Clinical Dietitian-Nutritionist",
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "MedicalBusiness",
            name:
              "Κλινική Διαιτολόγος - Διατροφολόγος Λευκωσία ΓΕΣΥ | Δρ. Σταυρούλα Αργυρίδου",
            image: "https://medindiet.com/img/logo.png",
            "@id": "",
            sameAs: [
              "https://www.facebook.com/medinidiet",
              "https://twitter.com/SArgyridou",
              "https://www.linkedin.com/in/stavroula-argyridou",
              "https://www.instagram.com/medindiet/",
            ],
            url: "https://medindiet.com",
            telephone: "+35799009222",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Katsoni 2 and Dimostheni Severi",
              addressLocality: "Nicosia",
              postalCode: "1082",
              addressCountry: "CY",
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: 35.161681,
              longitude: 33.3559063,
            },
            openingHoursSpecification: [
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                ],
                opens: "09:00",
                closes: "19:00",
              },
            ],
          },
        },
      ],
    };
  },
  data: function() {
    return {
      isDownloading: false,
    };
  },
  methods: {
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios() {
      const url = require("~/../../src/assets/medindiet_ebook.pdf");
      const title = "Βιβλιάριο_Διατροφικής_Υποστήριξης.pdf";
      this.isDownloading = true;
      axios({
        method: "get",
        url,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch()
        .finally(() => {
          this.isDownloading = false;
        });
    },
  },
  components: {
    GoogleMaps,
  },
};
</script>
