<template>
  <Layout>
    <div class="overflow-x-hidden justify-between pt-16">
      <h1 class="font-bold text-4xl mb-6 text-center" id="services">
        {{ $t("pages.services.services") }}
      </h1>
      <div class="container px-5 pt-10 pb-24 mx-auto text-gray-700 body-font">
        <div class="flex flex-wrap -m-4">
          <div class="xl:w-1/3 md:w-1/2 p-4" id="cardiovascular_disease">
            <div
              class="border-t-8 border-green-400 shadow-xl p-6 rounded-lg h-full"
            >
              <div
                class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-4"
              >
                <img
                  src="@/assets/services/cardiovascular_disease.svg"
                  alt="cardiovascular disease"
                />
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                {{ $t("pages.services.cardiovascular_disease") }}
              </h2>
              <p class="leading-relaxed text-base text-justify">
                {{ $t("pages.services.cardiovascular_disease_text") }}
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4" id="gastrointestinal_disorders">
            <div
              class="border-t-8 border-green-400 shadow-xl p-6 rounded-lg h-full"
            >
              <div
                class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-4"
              >
                <img
                  src="@/assets/services/gastrointestinal_disorders.svg"
                  alt="gastrointestinal disorders"
                />
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                {{ $t("pages.services.gastrointestinal_disorders") }}
              </h2>
              <p class="leading-relaxed text-base text-justify">
                {{ $t("pages.services.gastrointestinal_disorders_text") }}
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4" id="mental_health">
            <div
              class="border-t-8 border-green-400 shadow-xl p-6 rounded-lg h-full"
            >
              <div
                class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-4"
              >
                <img
                  src="@/assets/services/mental_health.svg"
                  alt="mental health"
                />
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                {{ $t("pages.services.mental_health") }}
              </h2>
              <p class="leading-relaxed text-base text-justify">
                {{ $t("pages.services.mental_health_text") }}
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4" id="nutrition_support">
            <div
              class="border-t-8 border-green-400 shadow-xl p-6 rounded-lg h-full"
            >
              <div
                class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-4"
              >
                <img
                  src="@/assets/services/nutrition_support.svg"
                  alt="nutrition support"
                />
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                {{ $t("pages.services.nutrition_support") }}
              </h2>
              <p class="leading-relaxed text-base text-justify">
                {{ $t("pages.services.nutrition_support_text") }}
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4" id="sports_and_exercise">
            <div
              class="border-t-8 border-green-400 shadow-xl p-6 rounded-lg h-full"
            >
              <div
                class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-4"
              >
                <img
                  src="@/assets/services/sports_and_exercise.svg"
                  alt="sports and exercise"
                />
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                {{ $t("pages.services.sports_and_exercise") }}
              </h2>
              <p class="leading-relaxed text-base text-justify">
                {{ $t("pages.services.sports_and_exercise_text") }}
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4" id="type_ii_diabetes_melitus">
            <div
              class="border-t-8 border-green-400 shadow-xl p-6 rounded-lg h-full"
            >
              <div
                class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-4"
              >
                <img
                  src="@/assets/services/type_ii_diabetes_melitus.svg"
                  alt="diabetes"
                />
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                {{ $t("pages.services.type_ii_diabetes") }}
              </h2>
              <p class="leading-relaxed text-base text-justify">
                {{ $t("pages.services.type_ii_diabetes_text") }}
              </p>
            </div>
          </div>

          <div class="xl:w-1/3 md:w-1/2 p-4" id="weight_management">
            <div
              class="border-t-8 border-green-400 shadow-xl p-6 rounded-lg h-full"
            >
              <div
                class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-4"
              >
                <img
                  src="@/assets/services/weight_management.svg"
                  alt="weight management"
                />
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                {{ $t("pages.services.weight_management") }}
              </h2>
              <p class="leading-relaxed text-base text-justify">
                {{ $t("pages.services.weight_management_text") }}
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4" id="womens_health">
            <div
              class="border-t-8 border-green-400 shadow-xl p-6 rounded-lg h-full"
            >
              <div
                class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-4"
              >
                <img
                  src="@/assets/services/womens_health.svg"
                  alt="women's health"
                />
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                {{ $t("pages.services.womens_health") }}
              </h2>
              <p class="leading-relaxed text-base text-justify">
                {{ $t("pages.services.womens_health_text") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title: locale == "gr" ? "Υπηρεσίες | MedInDiet" : "Services | MedInDiet",
      meta: [
        {
          name: "description",
          content:
            locale == "gr"
              ? "Μια ολοκληρωμένη διατροφική παρέμβαση με κέντρο τον διαιτώμενο. Μέσα από συνεδρίες γίνεται η αξιολόγηση και η διάγνωση η οποία οδηγεί στη  διαμόρφωση ενός πλάνου διατροφικής παρέμβασης και της τακτικής παρακολούθησης."
              : "We offer a complete patient-centred dietary intervention. This process involves the evaluation of the patient and their condition and a nutritional diagnosis which lead to the formulation of a diet intervention plan and regular monitoring.",
        },
        {
          name: "keywords",
          content:
            locale == "gr"
              ? "Καρδιαγγειακή νόσος, Διαταραχές του γαστρεντερικού, Ψυχική υγεία, Διατροφική υποστήριξη, Αθλήματα και άσκηση, Διαβήτης, Διαχείριση βάρους, Υγεία της γυναίκας"
              : "Cardiovascular Disease, Gastrointestinal disorders, Mental Health, Nutrition support, Sports and Exercise, Type II Diabetes, Weight Management, Women’s Health",
        },
      ],
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/services" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/services",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/services",
        },
      ],
    };
  },
};
</script>
