<template>
  <GmapMap
    ref="mapRef"
    :center="myCoordinates"
    :zoom="zoom"
    map-type-id="terrain"
    style="width: 100%; height: 460px;"
  >
    <GmapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      :draggable="false"
      @click="center=m.position"
      :title="MedInDiet"
    />
  </GmapMap>
</template>

<script>


export default {
  data() {
    return {
      map: null,
      myCoordinates: { lat: 35.1618108, lng: 33.3538651 },
      zoom: 17,
      markers: [
        {
          position: {
            lat: 35.1618108,
            lng: 33.3538651
          },
          title: "MedInDiet",
          draggable: false
        }
      ]
    };
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then(map => this.map = map);
  },
};
</script>

