<template>
  <Layout>
      <vue-calendly url="https://calendly.com/drargyridou/appointment" :height="700"></vue-calendly>
  </Layout>
</template>
 
<script>
export default {
  metaInfo() {
    var locale = this.$i18n.locale 
    return{ 
      title: locale == "gr" ? "Ραντεβού | MedInDiet" : "Appointments | MedInDiet",
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/appointments" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/appointments"
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/appointments"
        }
      ]
    }
  }
};
</script>
