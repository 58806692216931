<template>
  <Layout>
    <div class="overflow-x-hidden justify-between pt-16">
      <!-- Gesy -->
      <div class="container-inner mx-auto relative pb-16" id="gesy">
        <h2 class="font-bold text-4xl text-center pb-16">
          {{ $t("pages.gesy.gesy") }}
        </h2>
        <p class="text-justify">{{ $t("pages.gesy.gesy_text1") }}</p>
        <DoctorsList />
        <p class="text-justify">{{ $t("pages.gesy.gesy_text2") }}</p>
        <div class="container px-10 pt-8 mx-auto">
          <div class="lg:w-2/3 w-full mx-auto overflow-auto">
            <table class="table-auto w-full text-left whitespace-no-wrap">
              <thead>
                <tr>
                  <th
                    class="px-4 pb-3 title-font tracking-wider font-medium text-gray-900 text-base text-center"
                  >
                    {{ $t("pages.gesy.max_visits") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-t-2 border-gray-300 px-4 py-3">
                    {{ $t("pages.gesy.diseases_children") }}
                  </td>
                  <td class="border-t-2 border-gray-300 px-4 py-3">
                    4
                  </td>
                </tr>
                <tr>
                  <td class="border-t-2 border-b-2 border-gray-300 px-4 py-3">
                    {{ $t("pages.gesy.diseases_adults") }}
                  </td>
                  <td class="border-t-2 border-b-2 border-gray-300 px-4 py-3">
                    4
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <p class="text-justify pt-8">
          {{ $t("pages.gesy.gesy_text3") }}
        </p>
        <p class="text-justify pt-8">{{ $t("pages.gesy.gesy_text4") }}</p>
      </div>
    </div>
  </Layout>
</template>

<script>
import DoctorsList from "../components/DoctorsList";
export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title: locale == "gr" ? "ΓΕΣΥ | MedInDiet" : "GESY | MedInDiet",
      meta:
        locale == "gr"
          ? [
              {
                name: "description",
                content: "Online Διατροφή",
              },
              {
                name: "keywords",
                content:
                  "διαιτολόγος γεσυ, διαιτολόγος γεσυ Κύπρος, διαιτολόγος γεσυ Λευκωσία, διατροφολόγος γεσυ, διατροφολόγος γεσυ Κύπρος, διατροφολόγος γεσυ Λευκωσία",
              },
            ]
          : [
              {
                name: "description",
                content: "Online Diet",
              },
              {
                name: "keywords",
                content:
                  "dietitian gesy, dietitian gesy Cyprus, dietitian gesy Nicosia",
              },
            ],
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/gesy" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/gesy",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/gesy",
        },
      ],
    };
  },
  components: {
    DoctorsList,
  },
};
</script>
