<template>
  <Layout>
    <div class="overflow-x-hidden justify-between pt-16">
      <h1 class="font-bold text-4xl mb-6 text-center" id="services">
        {{ $t("pages.online_nutrition.online_nutrition") }}
      </h1>
      <div class="container-inner px-5 pt-10 pb-16 mx-auto body-font">
        <div class="text-justify pb-4">
          {{ $t("pages.online_nutrition.paragraph1") }}
          <router-link :to="`/${$i18n.locale}/appointments`">
            <span class="hover:text-gray-800">{{
              $t("pages.online_nutrition.here")
            }}</span>
          </router-link>
        </div>
        <div class="font-bold text-lg text-justify">
          {{ $t("pages.online_nutrition.given") }}
        </div>
        <section class="pt-5 pb-10">
          <div class="container px-5 mx-auto">
            <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="font-medium">{{
                    $t("pages.online_nutrition.given1")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="font-medium">{{
                    $t("pages.online_nutrition.given2")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="font-medium">{{
                    $t("pages.online_nutrition.given3")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="font-medium">{{
                    $t("pages.online_nutrition.given4")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="font-medium">{{
                    $t("pages.online_nutrition.given5")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="font-medium">{{
                    $t("pages.online_nutrition.given6")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="text-justify pb-4">
          {{ $t("pages.online_nutrition.paragraph2") }}
        </div>
        <div class="text-justify">
          {{ $t("pages.online_nutrition.paragraph3") }}
        </div>
      </div>
      <div class="pb-8 relative">
        <div class="container-inner mx-auto pb-80 lg:pb-64 lg:flex lg:flex-row">
          <div class="mx-auto">
            <div class="text-center">
              <router-link :to="`/${$i18n.locale}/appointments`">
                <input
                  type="submit"
                  name="book"
                  :value="$t('pages.online_nutrition.appointment')"
                  class="text-sm text-white tracking-wide font-semibold py-3 px-10 rounded-lg inline-flex shadow-lg items-center bg-green-500 hover:bg-green-600"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="absolute right-0 bottom-0 z-0">
          <img
            src="@/assets/illustrations/undraw_video_call.svg"
            alt="book appointment"
            class="h-80"
          />
        </div>
      </div>
      <!-- END Appointments -->
    </div>
  </Layout>
</template>

<script>
export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title:
        locale == "gr"
          ? "Online Διατροφή | MedInDiet"
          : "Online Diet | MedInDiet",
      meta:
        locale == "gr"
          ? [
              {
                name: "description",
                content: "Online Διατροφή",
              },
              {
                name: "keywords",
                content:
                  "online δίαιτα, online διαιτολόγος, online διαιτολόγος Κύπρος, online διαιτολόγος Λευκωσία, online διατροφολόγος, online διατροφολόγος Κύπρος, online διατροφολόγος Λευκωσία",
              },
            ]
          : [
              {
                name: "description",
                content: "Online Diet",
              },
              {
                name: "keywords",
                content:
                  "online diet, online dietitian, online dietitian Cyprus, online dietitian Nicosia",
              },
            ],
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/online_nutrition" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/online_nutrition",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/online_nutrition",
        },
      ],
    };
  },
};
</script>
