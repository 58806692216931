<template>
  <Layout>
    <div class="overflow-x-hidden justify-between pt-16">
      <div class="font-bold text-4xl mb-6 text-center" id="packages">
        {{ $t("pages.packages.packages") }}
      </div>
      <div class="container-inner mx-auto relative pt-10 pb-16">
        <h3 class="font-bold text-2xl pb-4 text-left">
          {{ $t("pages.packages.what_to_expect") }}
        </h3>
        <p class="text-justify">
          {{ $t("pages.packages.what_to_expect_text") }}
        </p>
        <section class="body-font py-10">
          <div class="container px-5 mx-auto">
            <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.factor_1")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.factor_2")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.factor_3")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.factor_4")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.factor_5")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.factor_6")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.factor_7")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.factor_8")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <p class="text-center">{{ $t("pages.packages.aforementioned") }}</p>
      </div>

      <div class="container-inner mx-auto relative pb-8">
        <h3 class="font-bold text-2xl pb-4 text-left">
          {{ $t("pages.packages.booking_appointment") }}
        </h3>
        <p class="text-justify">
          {{ $t("pages.packages.booking_appointment_text") }}
        </p>
      </div>

      <div class="container-inner mx-auto relative">
        <h3 class="font-bold text-2xl pb-4 text-left">
          {{ $t("pages.packages.first_consultation") }}
        </h3>
        <p class="text-justify">
          {{ $t("pages.packages.first_consultation_text") }}
        </p>
        <section class="body-font py-10">
          <div class="container px-5 mx-auto">
            <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.receive_1")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.receive_2")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.receive_3")
                  }}</span>
                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="shadow-lg rounded flex p-4 h-full items-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-green-500 w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span class="title-font font-medium">{{
                    $t("pages.packages.receive_4")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="container-inner mx-auto relative">
        <h3 class="font-bold text-2xl pb-4 text-left">
          {{ $t("pages.packages.follow_up") }}
        </h3>
        <p class="text-justify">{{ $t("pages.packages.follow_up_text") }}</p>
      </div>

      <div class="container-inner mx-auto">
        <div class="font-bold text-4xl text-center py-16">
          {{ $t("pages.packages.one_to_one") }}
        </div>
        <section class="body-font overflow-hidden">
          <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/2 w-full" id="one_off_package">
              <div
                class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden"
              >
                <div
                  class="text-2xl pb-4 mb-4 border-b border-gray-200 leading-none"
                >
                  {{ $t("pages.packages.one_off_package") }}
                </div>
                <p class="flex items-center mb-2">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.diet_plan") }}
                </p>
                <p class="flex items-center mb-2">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.body_analysis") }}
                </p>
                <p class="flex items-center mb-2">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.anthropometric_measurements") }}
                </p>
                <p class="flex items-center mb-2">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.blood_pressure") }}
                </p>
              </div>
            </div>
            <div class="p-4 md:w-1/2 w-full" id="full_package">
              <div
                class="h-full p-6 rounded-lg border-2 border-green-500 flex flex-col relative overflow-hidden"
              >
                <span
                  class="bg-green-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl"
                  >POPULAR</span
                >

                <div
                  class="text-2xl leading-none flex items-center pb-4 mb-4 border-b border-gray-200"
                >
                  {{ $t("pages.packages.full_package") }}
                </div>
                <p class="flex items-center mb-2">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.diet_plan") }}
                </p>
                <p class="flex items-center mb-2">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.body_analysis") }}
                </p>
                <p class="flex items-center mb-2">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.anthropometric_measurements") }}
                </p>
                <p class="flex items-center mb-2">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.blood_pressure") }}
                </p>
                <p class="flex items-center mb-6">
                  <span
                    class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      class="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5" />
                    </svg>
                  </span>
                  {{ $t("pages.packages.more_follow_ups") }}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="container-inner mx-auto relative pb-16">
        <div class="font-bold text-4xl text-center py-16">
          {{ $t("pages.packages.group_packages") }}
        </div>
        <div class="mx-auto relative pb-8" id="couples_and_families">
          <h3 class="font-bold text-2xl pb-4 text-left">
            {{ $t("pages.packages.couples_and_families") }}
          </h3>
          <p class="text-justify">
            {{ $t("pages.packages.couples_and_families_text") }}
          </p>
        </div>
        <div class="mx-auto relative pb-8" id="education">
          <h3 class="font-bold text-2xl pb-4 text-left">
            {{ $t("pages.packages.education") }}
          </h3>
          <p class="text-justify">{{ $t("pages.packages.education_text") }}</p>
        </div>
        <div class="mx-auto relative pb-8" id="corporate">
          <h3 class="font-bold text-2xl pb-4 text-left">
            {{ $t("pages.packages.corporate") }}
          </h3>
          <p class="text-justify">{{ $t("pages.packages.corporate_text") }}</p>
        </div>
        <div class="mx-auto relative" id="sports_teams">
          <h3 class="font-bold text-2xl pb-4 text-left">
            {{ $t("pages.packages.athletes") }}
          </h3>
          <p class="text-justify">{{ $t("pages.packages.athletes_text") }}</p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title: locale == "gr" ? "Πακέτα | MedInDiet" : "Packages | MedInDiet",
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/packages" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/packages",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/packages",
        },
      ],
    };
  },
};
</script>
